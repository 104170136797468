import request from '../request'
import qs from 'qs'
//  在线作业 -查询
const QueryStudentWork = (p, data) => {
    return request.post(`stuCourseDetail/queryStudentWork?${qs.stringify(p)}`, data).then(res => res.data);
}
//  在线作业 - 详情 - 答题记录
const QueryStudentWorkRecordDetails = (id) => {
    return request.get(`stuCourseDetail/queryStudentWorkRecordDetails/${id}`).then(res => res.data);
}
//  在线作业 - 详情
const QueryStudentWorkDetails = (id) => {
    return request.get(`stuCourseDetail/queryStudentWorkDetails/${id}`).then(res => res.data);
}
// 在线作业 - 预览 (教师端的)
const PreviewHomeWorkByWorkId = (p) => {
    return request.get(`eduCourseWorkSetTea/previewHomeWorkByWorkId?${qs.stringify(p)}`).then(res=>res.data);
}
// 在线作业 - 作答
const EduCourseStudentWorkAnswer = (data) => {
    return request.post(`stuCourseDetail/eduCourseStudentWorkAnswer`, data).then(res=>res.data);
}
//  在线考试 - 查询
const QueryStudentExam = (p, data) => {
    return request.post(`stuCourseDetail/queryStudentExam?${qs.stringify(p)}`, data).then(res => res.data);
}
//  在线考试 - 详情
const QueryStudentExamDetails = (id) => {
    return request.get(`stuCourseDetail/queryStudentExamDetails/${id}`).then(res => res.data);
}
//  在线考试 - 答题记录
const QueryStudentExamRecordDetails = (id) => {
    return request.get(`stuCourseDetail/queryStudentExamRecordDetails/${id}`).then(res => res.data);
}
// 在线考试 - 预览
const ExamPreview = (p) => {
    return request.get(`TeaCourseStudentExam/examPreview?${qs.stringify(p)}`).then(res => res.data);
}
// 在线考试 - 作答
const EduCourseStudentExamAnswer = (data) => {
    return request.post(`stuCourseDetail/eduCourseStudentExamAnswer`, data).then(res => res.data)
}
// 在线考试 - 答题记录 痕迹
const QueryStudentExamById = (p) => {
    return request.get(`eduCourseExamSet/queryStudentExamById?${qs.stringify(p)}`).then(res => res.data);
}
// 在线作业 - 答题记录 痕迹
const QueryStudentWorkById = (p) => {
    return request.get(`eduCourseExamSet/queryStudentWorkById?${qs.stringify(p)}`).then(res => res.data);
}
export {
    QueryStudentWork,
    QueryStudentWorkRecordDetails,
    QueryStudentWorkDetails,
    PreviewHomeWorkByWorkId,
    EduCourseStudentWorkAnswer,
    QueryStudentExam,
    QueryStudentExamDetails,
    QueryStudentExamRecordDetails,
    ExamPreview,
    EduCourseStudentExamAnswer,
    QueryStudentExamById,
    QueryStudentWorkById
}